import { Col, Row, Table, Button } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import "./index.less";
import CustomModal from "../../components/CustomModal";
import { notifyError, notifySuccess } from "../../common/notification";

// It's shows the card catalog info
class index extends Component {
  state = {
    overdueData: [],
    statusData: {},
    statusModal: false,
    statusAction: "",
  };
  async componentDidMount() {
    if (this?.props?.location?.state?.data) {
      this.setState({ overdueData: this?.props?.location?.state?.data });
    } else {
      await this.props.loadUserDashboardMiscData();
      this.setState({
        overdueData: this?.props?.userMiscDataDashoard?.passOverdueData || [],
      });
    }
  }

  handleStatusModal = (data, action) => {
    const { statusModal } = this.state;
    this.setState({
      statusData: data ? data : {},
      statusModal: !statusModal,
      statusAction: action ? action : "",
    });
  };

  submitUserAction = () => {
    this.setState({ statusModal: false });
    this.props
      .updateUserOverdueData({
        transaction_id: this.state.statusData.transaction_id,
        user_id: this.state.statusData.user_id,
        is_paid: this.state.statusAction == "paid" ? 1 : null,
        is_deleted: this.state.statusAction == "delete" ? 1 : null,
      })
      .then(async (res) => {
        if (res && res.data && res.data.status) {
          this.setState({
            statusModal: false,
            statusAction: "",
            statusData: {},
          });
          notifySuccess(res?.data?.message || "Done");
          await this.props.loadUserDashboardMiscData();
          this.setState({
            overdueData:
              this?.props?.userMiscDataDashoard?.passOverdueData || [],
          });
        } else {
          var errorMessage =
            res?.data?.message || res?.err?.message || "Something went wrong";
          notifyError(errorMessage);
        }
      });
  };

  // Render the card info in table formate
  columns = [
    {
      title: "User ID",
      dataIndex: "user_id",
      key: "user_id",
      render: (_text, record) => <p>{record.user_id || ""}</p>,
    },
    {
      title: "User Email",
      dataIndex: "user_email",
      key: "user_email",
      render: (_text, record) => (
        <p style={{ width: "150px" }}>{record.user_email || ""}</p>
      ),
    },
    {
      title: "Display Name",
      dataIndex: "display_name",
      key: "display_name",
      render: (_text, record) => (
        <p style={{ width: "150px" }}>{record.display_name || ""}</p>
      ),
    },
    {
      title: "Merchant Name",
      dataIndex: "merchant_name",
      key: "merchant_name",
      render: (_text, record) => (
        <p style={{ width: "150px" }}>{record.merchant_name || ""}</p>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_text, record) => <p>{record.amount || ""}</p>,
    },
    {
      title: "Actual Timestamp",
      dataIndex: "actual_timestamp",
      key: "actual_timestamp",
      render: (_text, record) => <p>{record.actual_timestamp || ""}</p>,
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (_text, record) => <p>{record.timestamp || ""}</p>,
    },
    {
      title: "Actions",
      key: "action",
      render: (_text, record) => {
        return (
          <div className="btn-actions" style={{ width: "250px" }}>
            <span
              style={{
                marginRight: 16,
              }}
              onClick={() => {
                this.handleStatusModal(record, "paid");
              }}
            >
              Mark as Paid
            </span>
            <span
              onClick={() => {
                this.handleStatusModal(record, "delete");
              }}
            >
              Delete Bill
            </span>
          </div>
        );
      },
    },
  ];

  render() {
    const { overdueData, statusModal } = this.state;
    let results = [];
    // console.log("overdueData ==>", overdueData);
    if (overdueData && overdueData.length > 0) {
      for (var i = 0; i < overdueData.length; i++) {
        var key = `user-overdue-data-${i}-index-overdue`;

        results.push({
          sr_no: i + 1,
          key: key,
          ...overdueData[i],
        });
      }
    }

    return (
      <BasicLayout>
        <Row className="section-title">
          <Col md={24}>
            <h4>User Overdue Data! ( {overdueData.length} )</h4>
          </Col>
        </Row>
        <Row>
          <Table
            dataSource={results}
            columns={this.columns}
            sortOrder={false}
            pagination={false}
          />
        </Row>
        <CustomModal
          className={"signup-setting-modal"}
          visible={statusModal}
          onClose={this.handleStatusModal}
        >
          <p className="txt-title">Are you sure?</p>
          <p className="txt-info">This change cannot be undone.</p>

          <Button
            type="primary"
            block
            onClick={() => this.submitUserAction("delete")}
          >
            Yes
          </Button>
        </CustomModal>
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    userMiscDataDashoard: state.userMiscDataDashoard,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
