import {
  Button,
  Col,
  Row,
  Form,
  Input,
  Table,
  Select,
  Descriptions,
  List,
} from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import CustomModal from "../../components/CustomModal";
import { notifyError, notifySuccess } from "../../common/notification";
import "./index.less";

// It's shows the card catalog info
class index extends Component {
  formRef = React.createRef();

  state = {
    addNotificationModal: false,
    saveNotificationBroadcastData: null,
    selectedUsers: [],
    statusData: {},
    statusModal: false,
  };

  async componentDidMount() {
    this.props.loadNotificationData();
    if (this.props.users.length == 0) {
      await this.props.loadUsers();
    }
    if (this.props.partnerOnboardingCodes.length == 0) {
      await this.props.loadPartnerOnboardingCodes();
    }
  }

  deleteActionSubmit = () => {};

  // Add, Edit and Delete Icons
  submitForm = (values) => {
    this.props.saveNotificationData(values).then((res) => {
      if (res && res.data && res.data.status) {
        this.setState({
          addNotificationModal: false,
          saveNotificationBroadcastData: null,
          selectedUsers: [],
        });
        notifySuccess(res?.data?.message || "Done");
        this.props.loadNotificationData(null);
      } else {
        var errorMessage =
          res?.data?.message || res?.err?.message || "Something went wrong";
        notifyError(errorMessage);
      }
    });
  };

  handleAddModal = () => {
    const { addNotificationModal } = this.state;
    this.setState({
      addNotificationModal: !addNotificationModal,
    });
  };
  handleDropdownItemClick = (e) => {
    // console.log("e ==>", e);
  };

  handleCloseModal = () => {
    this.setState({
      addNotificationModal: false,
    });
  };

  handleStatusModal = (data) => {
    const { statusModal } = this.state;
    this.setState({
      statusData: data ? data : {},
      statusModal: !statusModal,
    });
  };

  handleClick = async (item) => {
    await this.formRef.current.setFieldsValue({
      user_list: [],
    });
    let filterList = [];
    if (item.type == "partner_code") {
      await this.props.users.map((data) => {
        if (Number(data._partner_id) == Number(item?.id)) {
          filterList.push(data.u_email_id);
        }
      });
      this.setState({
        selectedUsers: filterList,
      });
      await this.formRef.current.setFieldsValue({
        user_list: filterList,
      });
    }

    if (item.type == "all_users") {
      this.setState({
        selectedUsers: this.props.users,
      });
      await this.formRef.current.setFieldsValue({
        user_list: this.props.users,
      });
    }

    if (item.type == "onboarding_stuck") {
      let userArray = this.props?.getUserGroupData?.[0]?.["onboarding_stuck"];
      await this.props.users.map((data) => {
        if (userArray.includes(data.user_id)) {
          filterList.push(data.u_email_id);
        }
      });
      this.setState({
        selectedUsers: filterList,
      });
      await this.formRef.current.setFieldsValue({
        user_list: filterList,
      });
    }

    if (item.type == "active_accounts") {
      let userArray = this.props?.getUserGroupData?.[0]?.["active_accounts"];
      await this.props.users.map((data) => {
        if (userArray.includes(data.user_id)) {
          filterList.push(data.u_email_id);
        }
      });
      this.setState({
        selectedUsers: filterList,
      });
      await this.formRef.current.setFieldsValue({
        user_list: filterList,
      });
    }
  };

  // Render the card info in table formate
  columns = [
    {
      title: "#",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (_text, record) => (
        <p
          style={{
            width: 100,
          }}
        >
          {record.sr_no || ""}
        </p>
      ),
    },
    {
      title: "Title",
      dataIndex: "notification_title",
      key: "notification_title",
      render: (_text, record) => (
        <p
          style={{
            width: 200,
          }}
        >
          {record.notification_title || ""}
        </p>
      ),
    },
    {
      title: "Body",
      dataIndex: "notification_body",
      key: "notification_body",
      render: (_text, record) => (
        <p
          style={{
            width: 300,
          }}
        >
          {record.notification_body || ""}
        </p>
      ),
    },
    {
      title: "Actions",
      key: "action",
      render: (_text, record) => {
        return (
          <div className="btn-actions">
            <span
              onClick={() => {
                this.handleStatusModal(record?.notification_status_info || "");
              }}
            >
              View Status
            </span>
          </div>
        );
      },
    },
  ];

  render() {
    const {
      users,
      notificationBroadcastData,
      partnerOnboardingCodes,
      getUserGroupData,
    } = this.props;
    const {
      addNotificationModal,
      saveNotificationBroadcastData,
      selectedUsers,
      statusModal,
    } = this.state;
    let results = [];
    let userGroupData = [
      {
        label: "All active users on app",
        type: "all_users",
        id: "all_users",
      },
      {
        label: "Stuck on Onboarding",
        type: "onboarding_stuck",
        id: "onboarding_stuck",
      },
      {
        label: "No. active accounts total group",
        type: "active_accounts",
        id: "active_accounts",
      },
    ];

    for (let partnerData of partnerOnboardingCodes) {
      userGroupData.push({
        label:
          partnerData?.partner_name == "All users"
            ? "Partner code - All users"
            : partnerData?.partner_name,
        type: "partner_code",
        id: partnerData?.app_partner_onboarding_codes_id,
      });
    }

    let passSearchData = users.map((data) => ({
      label: data?.u_email_id,
      value: data?.u_email_id,
    }));
    if (notificationBroadcastData && notificationBroadcastData.length > 0) {
      for (var i = 0; i < notificationBroadcastData.length; i++) {
        var key = `notification-broadcast-${notificationBroadcastData[i].admin_notification_broadcast_id}`;
        results.push({
          sr_no: i + 1,
          key: key,
          ...notificationBroadcastData[i],
        });
      }
    }

    return (
      <BasicLayout>
        <Row className="section-title">
          <Col md={24}>
            <h4>Notification Broadcast</h4>
          </Col>
          <Col md={23}>
            <div>
              <Button
                className="btn-add-card"
                type="primary"
                onClick={this.handleAddModal}
              >
                New Broadcast
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Table
            dataSource={results}
            columns={this.columns}
            sortOrder={false}
            pagination={false}
          />
        </Row>

        {addNotificationModal && (
          <CustomModal
            className={"signup-setting-modal"}
            visible={addNotificationModal}
            onClose={this.handleCloseModal}
          >
            <Form
              ref={this.formRef}
              size="large"
              initialValues={saveNotificationBroadcastData}
              onFinish={(val) => this.submitForm(val)}
              layout="vertical"
              className="form-login"
            >
              <p className="txt-title">Add notification data</p>
              <div className="custom-form-container">
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[
                    {
                      required: true,
                      message: "Please enter notification title",
                    },
                  ]}
                >
                  <Input
                    placeholder="Notification title"
                    type="text"
                    value={saveNotificationBroadcastData?.title || ""}
                  />
                </Form.Item>

                <Form.Item
                  name="body"
                  label="Body"
                  rules={[
                    {
                      required: true,
                      message: "Please enter notification body",
                    },
                  ]}
                >
                  <Input
                    placeholder="Notification body"
                    type="text"
                    value={saveNotificationBroadcastData?.body || ""}
                  />
                </Form.Item>

                <Form.Item
                  name="user_list"
                  label="User List"
                  rules={[
                    {
                      required: true,
                      message: "Please select users",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    showSearch
                    allowClear
                    value={selectedUsers}
                    className="report-dropdown"
                    style={{ width: "100%" }}
                    placeholder="Type to search users"
                    onChange={(data) => {
                      this.handleDropdownItemClick(data);
                    }}
                    options={passSearchData}
                  />
                </Form.Item>
              </div>

              <List
                size="small"
                // header={<div>Groups</div>}
                bordered
                dataSource={userGroupData}
                renderItem={(item) => {
                  const getBackgroundColor = (category) => {
                    switch (category) {
                      case "all_users":
                        return "#87CEEB"; // Light blue
                      case "onboarding_stuck":
                        return "#DAA520"; // Light gray
                      case "active_accounts":
                        return "#CD853F"; // Beige
                      case "partner_code":
                        return "#4682B4"; // Beige
                      default:
                        return "#ffffff"; // White (default)
                    }
                  };
                  return (
                    <List.Item
                      onClick={() => this.handleClick(item)}
                      style={{
                        backgroundColor: getBackgroundColor(item?.type),
                      }}
                    >
                      {item?.label}
                    </List.Item>
                  );
                }}
              />

              <Button
                className="text-gray"
                htmlType="submit"
                type="primary"
                block
              >
                Save
              </Button>
            </Form>
          </CustomModal>
        )}
        <CustomModal
          className={"signup-setting-modal"}
          visible={statusModal}
          onClose={this.handleStatusModal}
        >
          {
            <Descriptions
              title="Status Info of success and failed tokens!"
              bordered
            >
              {Object.entries(this.state.statusData).map(([key, value]) => (
                <Descriptions.Item label={key} key={key}>
                  {value}
                </Descriptions.Item>
              ))}
            </Descriptions>
          }
        </CustomModal>
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    users: state.users,
    notificationBroadcastData: state.notificationBroadcastData,
    partnerOnboardingCodes: state.partnerOnboardingCodes,
    getUserGroupData: state.getUserGroupData,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
