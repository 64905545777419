import { types as T } from "../actions/action_types";

//import { setIn } from "../common/utils";

const initialState = {
  route: null,
  isLoading: 0,
  isLoggedIn: false,
  adminInfo: {},
  dashboard: {},
  users: [],
  admins: [],
  active_user: {},
  card_catalog: [],
  rewards: [],
  completeTaskList: [],
  merchant_icons: [],
  marketplaceClickInfo: [],
  tagInfo: [],
  merchantListingInfo: [],
  councilTaxInfo: [],
  generalTabReports: {},
  financialTabReports: {},
  partnerOnboardingCodes: [],
  consumerDutyTabReports: {},
  consumerDutyTabClicks: [],
  consumerDutyTabClicksLastMonth: [],
  collectionTabReports: {},
  lastMonthAdminData: {},
  secondLastMonthAdminData: {},
  thirdLastMonthAdminData: {},
  spinTheWheelData: [],
  spinTheWheelCount: 0,
  userVoucherData: [],
  userPageSelect: 1,
  subCodesList: [],
  subCodeUsedList: [],
  cashbackRetailerList: [],
  userBillList: [],
  userGiftLogsList: [],
  moneyplusDueData: [],
  notificationBroadcastData: [],
  getUserGroupData: [],
  userMiscDataDashoard: [],
};

const requestReg = /_REQUEST/;
const resultReg = /_SUCCESS|_FAIL/;

export default function reducer(state = initialState, action) {
  const isRequest =
    requestReg.test(action.type) &&
    action.type.indexOf("LOAD_USER_INFO") === -1 &&
    action.type.indexOf("VIEW_USER_INFO_LIVE") === -1;
  const isResult =
    resultReg.test(action.type) &&
    action.type.indexOf("LOAD_USER_INFO") === -1 &&
    action.type.indexOf("VIEW_USER_INFO_LIVE") === -1;
  // set loading state for if any api calling
  if (isRequest) {
    state = { ...state, isLoading: state.isLoading + 1 };
  } else if (isResult) {
    let load = state.isLoading - 1;
    if (load < 0) load = 0;
    state = { ...state, isLoading: load };
  }
  // reducer action and set according in to reducer state
  switch (action.type) {
    case T.SET_ROUTE:
      return {
        ...state,
        route: action.data,
      };
    case T.LOGOUT:
      return {
        ...state,
        isLoading: 0,
        isLoggedIn: false,
        adminInfo: {},
        dashboard: {},
        users: [],
        admins: [],
        active_user: {},
        card_catalog: [],
        rewards: [],
        completeTaskList: [],
        userPageSelect: 1,
        subCodesList: [],
        subCodeUsedList: [],
        cashbackRetailerList: [],
        userBillList: [],
        userGiftLogsList: [],
        uniqueLoginHistory: [],
      };
    case T.SET_ADMININFO:
    case T.LOAD_USER_INFO_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        adminInfo: action.data,
      };

    case T.GET_DASHBOARD_STATS_SUCCESS:
      return {
        ...state,
        dashboard: action?.data?.data || {},
      };

    case T.GET_USER_BILLS_SUCCESS:
      return {
        ...state,
        userBillList: action?.data || [],
      };

    case T.USER_CURRENT_PAGE_SUCCESS:
      return {
        ...state,
        userPageSelect: action?.data || 1,
      };

    case T.GET_COMPLETETASK_LIST_SUCCESS:
      return {
        ...state,
        completeTaskList: action?.data?.data || {},
      };

    case T.GET_REWARD_LIST_SUCCESS:
      return {
        ...state,
        rewards: action?.data?.data || {},
      };

    case T.GET_USERS_SUCCESS:
      return {
        ...state,
        users: action?.data?.data || {},
      };

    case T.GET_ADMIN_USERS_SUCCESS:
      return {
        ...state,
        admins: action?.data?.data || {},
      };

    case T.VIEW_USER_INFO_SUCCESS:
      return {
        ...state,
        active_user: action?.data?.data || {},
      };

    case T.VIEW_USER_INFO_LIVE_SUCCESS:
      if (
        state.active_user?.info?.user_id === action?.data?.data?.info?.user_id
      ) {
        return {
          ...state,
          active_user: action?.data?.data || {},
        };
      } else {
        // console.log("Not a same user");
        return {
          ...state,
        };
      }

    case T.GET_CARD_CATALOG_SUCCESS:
      return {
        ...state,
        card_catalog: action?.data?.data || [],
      };

    case T.GET_MERCHANT_ICONS_SUCCESS:
      return {
        ...state,
        merchant_icons: action?.data?.data || [],
      };

    case T.GET_MARKETPLACE_CLICK_INFO_SUCCESS:
      return {
        ...state,
        marketplaceClickInfo: action?.data?.data || [],
      };

    case T.GET_TAGS_SUCCESS:
      return {
        ...state,
        tagInfo: action?.data?.data || [],
      };

    case T.GET_MERCHANT_LISTING_SUCCESS:
      return {
        ...state,
        merchantListingInfo: action?.data?.data || [],
      };

    case T.GET_COUNCIL_TAX_SUCCESS:
      return {
        ...state,
        councilTaxInfo: action?.data?.data || [],
      };

    case T.GET_GENERAL_TAB_REPORTS_SUCCESS:
      return {
        ...state,
        generalTabReports: action?.data?.data || {},
      };

    case T.GET_FINANCIAL_TAB_REPORTS_SUCCESS:
      return {
        ...state,
        financialTabReports: action?.data?.financialTabReport || {},
        collectionTabReports: action?.data?.collectionTabReport || {},
        lastMonthAdminData: action?.data?.lastMonthData || {},
        secondLastMonthAdminData: action?.data?.secondLastMonthData || {},
        thirdLastMonthAdminData: action?.data?.thirdLastMonthData || {},
      };

    case T.GET_PARTNER_ONBOARDING_CODE_SUCCESS:
      return {
        ...state,
        partnerOnboardingCodes: action?.data?.data || {},
      };

    case T.GET_CONSUMER_DUTY_TAB_REPORTS_SUCCESS:
      return {
        ...state,
        consumerDutyTabReports: action?.data?.data || {},
        consumerDutyTabClicks: action?.data?.clicksData || [],
        consumerDutyTabClicksLastMonth: action?.data?.lastClicksData || [],
      };

    case T.GET_SPIN_THE_WHEEL_SUCCESS:
      return {
        ...state,
        spinTheWheelData: action?.data?.data || [],
        spinTheWheelCount: Number(action?.data?.spinCount) || 0,
      };

    case T.GET_USER_VOUCHER_SUCCESS:
      return {
        ...state,
        userVoucherData: action?.data?.data || [],
      };

    case T.GET_SUB_CODE_LIST_SUCCESS:
      return {
        ...state,
        subCodesList: action?.data?.data || [],
      };

    case T.GET_USED_SUB_CODE_LIST_SUCCESS:
      return {
        ...state,
        subCodeUsedList: action?.data?.data || [],
      };

    case T.GET_CASHBACK_RETAILERS_LIST_SUCCESS:
      return {
        ...state,
        cashbackRetailerList: action?.data?.data || [],
      };

    case T.GET_USER_GIFT_LOGS_SUCCESS:
      return {
        ...state,
        userGiftLogsList: action?.data?.data || [],
      };

    case T.GET_UNIQUE_LOGIN_DATA_SUCCESS:
      return {
        ...state,
        uniqueLoginHistory: action?.data?.data || [],
      };

    case T.GET_MONEYPLUS_DUE_DATA_SUCCESS:
      return {
        ...state,
        moneyplusDueData: action?.data?.data || [],
      };

    case T.GET_NOTIFICATION_BROADCAST_DATA_SUCCESS:
      return {
        ...state,
        notificationBroadcastData: action?.data?.data || [],
        getUserGroupData: action?.data?.groupData || [],
      };

    case T.GET_USER_MISC_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        userMiscDataDashoard: action?.data?.data || [],
      };

    default:
      return state;
  }
}
