import { Col, Row, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import "./index.less";
import CustomModal from "../../components/CustomModal";

// It's shows the card catalog info
class index extends Component {
  state = {
    overdueData: [],
  };
  async componentDidMount() {
    if (this?.props?.location?.state?.data) {
      this.setState({ overdueData: this?.props?.location?.state?.data });
    } else {
      await this.props.loadUserDashboardMiscData();
      this.setState({
        overdueData: this?.props?.userMiscDataDashoard?.passUnlikelyData || [],
      });
    }
  }

  // Render the card info in table formate
  columns = [
    {
      title: "User ID",
      dataIndex: "user_id",
      key: "user_id",
      render: (_text, record) => <p>{record.user_id || ""}</p>,
    },
    {
      title: "User Email",
      dataIndex: "user_email",
      key: "user_email",
      render: (_text, record) => <p>{record.user_email || ""}</p>,
    },
    {
      title: "Display Name",
      dataIndex: "display_name",
      key: "display_name",
      render: (_text, record) => <p>{record.display_name || ""}</p>,
    },
    {
      title: "Merchant Name",
      dataIndex: "merchant_name",
      key: "merchant_name",
      render: (_text, record) => <p>{record.merchant_name || ""}</p>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_text, record) => <p>{record.amount || ""}</p>,
    },
    {
      title: "Actual Timestamp",
      dataIndex: "actual_timestamp",
      key: "actual_timestamp",
      render: (_text, record) => <p>{record.actual_timestamp || ""}</p>,
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (_text, record) => <p>{record.timestamp || ""}</p>,
    },
  ];

  render() {
    const { overdueData } = this.state;
    let results = [];
    console.log("overdueData ==>", overdueData);
    if (overdueData && overdueData.length > 0) {
      for (var i = 0; i < overdueData.length; i++) {
        var key = `user-unlikely-data-${i}-index-afford`;

        results.push({
          sr_no: i + 1,
          key: key,
          ...overdueData[i],
        });
      }
    }

    return (
      <BasicLayout>
        <Row className="section-title">
          <Col md={24}>
            <h4>User Unlikely to afford Data!</h4>
          </Col>
        </Row>
        <Row className="card-table">
          <Table
            dataSource={results}
            columns={this.columns}
            sortOrder={false}
            pagination={false}
          />
        </Row>
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    userMiscDataDashoard: state.userMiscDataDashoard,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
